/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 105%;
	line-height: 1.6;
	color: $black;
    font-weight:500;
} 

.mobileOnly {display:inline-block;}
.desktopOnly {display:none;}
os-animation {visibility:hidden;}
.animated {visibility:visible;}
/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
Z_INDEX
*********************/
.cd-top { z-index: 9999;}
.header { z-index: 901;}
#bookForm { z-index: 900;}
ul.children {z-index: 800;}
#hero {z-index: 0;}
#content, #bottomBlocks, #footer{z-index: 1;}
.slick-slider button, .caption  {z-index: 9999;}
/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.Home #content #leftcol > div > div, .row-small, body.fullWidth #leftcol > div > div {
	margin: 0 auto;
	width: 90%;
	padding: 1em 0;
}
body.twoColumns #content #leftcol > div > div{
	padding-bottom: 1em;
}


/* Turn off padding on some rows*/
.header .row { padding: 0.3em 0;}



/*********************
LINK STYLES
*********************/

a {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}



/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2 {
    font-family: $headings;
    font-display: fallback;
    line-height: 1.2;

    a {
        text-decoration: none;
    }
}

h3, .h3, h4, .h4, h5, .h5 {
    line-height: 1.3;
}

.fal {
    font-weight: 300;
}
h1, .h1, h2, .h2 {font-weight: 700;}
h4, h3, .h3, h6, .h6 {font-weight: 500;}
strong,  h5, .h5, .h4, .link {font-weight: 600;}

h1, .h1, h2, .h2, h3, .h3, h4, .h4{margin:0.35em 0;}
h5, .h5, h6, .h6{margin:0.75em 0;}
#accountMenu .h2, #address .h2, .slideshow_caption .h1,  .slideshow_caption .h2  {margin:0em 0;}

h4, h3, .h3, h5, .h5, .siteName strong, .topMenu .nav > li > a, thead, .button {text-transform:uppercase;}
h3, .h3, h5, .h5, .siteName strong, .topMenu .nav > li > a, thead, .button {letter-spacing:1px;}

h1, .h1, h2, .h2, .phone { font-size: 1.6em;}
h4, .h4, .slideDescription, .fa-ul li, .bigger, .topBlock > div p, .phone {
    font-size: 1.2em;
}
 h5, .h5, #breadcrumbs .siteName strong, .accountMenu {font-size: 1em;}

 .legend span {font-size: 1.2em;}


h3, .h3 {font-size: 1.2em;}

a.link, a.link-2, a.button {
    i {
        display: inline-block;
        padding-left: 0.25em;
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }

    &:hover i {
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
    }
}


.responsive .h3:after {display:none; margin-bottom:0; }

.smtext, #breadcrumbs, thead, .respond-form label, .holdForm ul,  h6, .h6 { font-size: 0.85em; }
.xsmtext { font-size: 0.65em; }



/*************************
Colours
*************************/


.green, .darkHeader #accountMenu, #accountMenu .h2, .darkHeader .phone, body.Home #content ul li::before, blockquote:before, body.Home .columnlayout h2 a:hover,
.respond-form h2, .fa-li, .slideshow_caption a.button, a.button.secondary:hover,
body.pushy-open-left .clearHeader .topMenu .nav > li > a, body.pushy-open-left .clearHeader #accountMenu a, body.pushy-open-left .clearHeader a.menu-btn,
.button.tertiary:hover, #rightcol ul li a:hover, .wrap-txt a.link:hover, .wrap-txt .h3 a:hover, .wrap-txt .h4 a:hover, .text-wrap a.link:hover, .text-wrap .h3 a:hover, .text-wrap h4 a:hover {
    color: $color-primary;
}


.black, .topMenu .nav > li > a,
a.button, button, h2 a,
body.Home #content #leftcol > div:first-of-type,
ul.responsive3 .h2.slideDescription,
#breadcrumbs, body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd) h2 a,
#rightcol ul li a, .wrap-txt .h3 a, .wrap-txt a.link, .wrap-txt .h4 a, a.button.secondary, a.button.secondary:hover, .text-wrap .h3 a, .text-wrap a.link, .text-wrap h4 a {
    color: $black;
}

.grey
{color: $color-tertiary; }

.white,
ul.responsive3 .slideshow_caption a,
.slick-slider button:before,
.responsive .h3, .cText, .cText > a, .cText .holdText h2,
.holdForm, .holdForm h2, .holdForm a.button,
.cd-top i, #cta a.button, .button.primary, a.button.primary, .button.tertiary,
#hero .slick-slider button:before, #hero .h1, #hero h1,
#bottomBlocks, #bottomBlocks h3, #bottomBlocks a,
body.Home .clearHeader .topMenu .nav > li > a, body.Home .clearHeader #accountMenu a, body.Home .clearHeader a.menu-btn, .caption .h3, .backgroundText ul li:before, a.button:hover {
    color: $white;
}


    ::-webkit-input-placeholder { /* Edge */
      color: $color-secondary;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: lighten($color-secondary, 20%);
    }

    ::placeholder {
      color: lighten($color-secondary, 20%);
    }

#bottomBlocks{
    ::-webkit-input-placeholder { /* Edge */
      color: $white;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $white;
    }

    ::placeholder {
      color: $white;
    }
}

.blue, i:not(#accountMenu i):not(a > i):not(.noMarker i) {
    color: $color-secondary
}

/*************************
Turn off printable items
*************************/	
	.printable {display: none;}
	

/*********************
SPRITE
*********************/
/*use modernizer script stop detect browser fallback for next gen images*/
/*.no-webp .box { color: red; }
.webp .box { color: green; }*/	
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo.png'); }
.webp #logo { background-image: url('../images/logo.webp'); }*/

    #logo {
		display: block;
		margin: 0 auto;
        background: transparent url('../images/logo.svg') no-repeat center top;
        background-position: center top;
        width: 179px; height: 65px;
        background-size: 179px 65px;
	}

body.Home .clearHeader #logo {
    background: transparent url('../images/logowhite.svg') no-repeat center top;
}

body.pushy-open-left {
    .header {
        .clearHeader #logo{
            background: transparent url('../images/logo.svg') no-repeat center top;
        }
    }
}

/*********************
HEADER STYLES
*********************/

/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */

body:not(.Home) .clearHeader {
    background-color: $white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    position: relative;
}

.header {

    .clearHeader {
        position: absolute;
        background-color: transparent;
        @include transition(all 0.2s ease-in-out);
        z-index: 999;
    }

    .darkHeader {
        position: fixed;
        background-color: $white;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        @include transition(all 0.2s ease-in-out);
        top: 0;
        z-index: 999;
    }
}

/* position header elements as needed */

.header {

    .row {
        min-height: 80px;
        position: relative;
    }


    .menu {
        width: 100%;
    }
    /*.menu-btn, #logo, #accountMenu {
        position: absolute;
    }*/

    #accountMenu {
        right: 0em;
        @include center(false, true);


        a {
            padding: 0.5em 1em;
            display: inline-block;
            margin: 0em 0em 0em 0.35em;
        }

        .mobileOnly {
            margin-left: 0.5em;
        }

        span.desktopOnly {
        }
    }

    .menu-btn {
        cursor: pointer;
        @include center(false, true);
        text-align: center;
        margin: 0 0.25em;

        span {
            display: block;
            line-height: 1;
        }
    }

    #logo {
        position: absolute;
        left: 15%;
        top: 0.5em;
    }

    #mainMenu {
        display: none;
    }
}



/***********************
HERO - HERO
************************/

#hero {
    position: relative;
    overflow: hidden;

}

body.Home #hero {
    height: 100vh;
    /*.row { @include center(true, true); width: 85%;}*/
    .caption {
        @include center(true, true);
        width: 85%;
    }

    .row {
        @include center(false, true);
        left: 10%;
        width: 50%;
    }
}



body:not(.Home) #hero {
    position: relative;
    overflow: hidden;
    height: 250px;
    text-align: center;

        > div p {
            margin: 0 0;
            position: relative;
        }

        > div p picture {
            display: block;
            position: relative;
        }

        .heading {
            @include center(true, true);
        }


        p img {
            position: relative;
            display: block;
            z-index: -1;
            min-width: 100%;
            min-height: 250px;
            max-width: none;
            width: auto !important;
        }

        .heading {
            padding: 1em;
            width: 90%;
            z-index: 2;
            text-align: center;
            @include center(true, true);
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.35);
            z-index: 0;
        }

        .custom-shape {
            display: none;
        }

        .custom-shape svg {
            display: none;
        }
    }
    /*********************
NAVIGATION STYLES
*********************/
    #touchMenu,
    .menu-btn {
        display: block;
    }

    #mainMenu {
        display: none;
    }

    #pushMenu {
        ul:nth-of-type(2) {
            > li:first-of-type {
                display: none;
            }
            /*hide home link in 2nd nav*/
        }
    }

    .topMenu, #pushMenu {
        /*list-style:none;
	float: right;
	clear: right;*/


        a.toggleMenu {
            width: 100%;
            padding: 2% 4%;
            text-transform: uppercase;
            margin-bottom: 0;

            span {
                display: block;
                width: auto;
                float: right;
                padding-left: 0.5em;
            }
        }

        .nav {
            /*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/

            li {
                position: relative;
                text-align: left;

                a {
                    display: block;
                    text-decoration: none;
                    padding: 0.5em 0.5em;
                    background: $white;
                    border-bottom: 1px solid $color-primary;

                    &:hover, &:focus {
                        background: $white;
                    }

                    &.parent:before {
                        content: "\f107";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        display: block;
                        float: right;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 1.2;
                    }
                }

                &.hover {
                    > a.parent:before {
                        content: "\f106";
                    }
                }

                &:first-child {
                }

                &:last-child {
                }
                /* Sub-menu level 2 */
                ul,
                ul.sub-menu,
                ul.children {
                    margin-top: 0;
                    border-top: 0;
                    display: none;

                    li {

                        a {
                            padding-left: 1em;
                            background: lighten($black, 85%);
                            border-bottom: 1px solid rgba(130, 154, 193, 0.21);

                            &:hover,
                            &:focus {
                                background: darken($black, 0%);
                            }

                            &:link {
                            }
                        }

                        &:first-child {
                        }

                        &:last-child {
                        }
                        /* Sub-sub-menu level 3	*/
                        ul {

                            li {

                                a {
                                    padding-left: 1.5em;
                                    background: $white;

                                    &:hover,
                                    &:focus {
                                        background: darken($white, 5%);
                                    }
                                }
                                /* Sub-sub-menu level 4	*/
                                ul {

                                    li {

                                        a {
                                            padding-left: 2em;
                                            background: lighten($black, 30%);
                                        }
                                    }
                                }
                                /* end level 4	*/
                            }
                        }
                        /* end level 3	*/
                    }
                }
                /* end level 2	*/
                /* Hover effects */
                &:hover ul {
                }
            }
            /* end .menu li */
        }
        /* end .nav */

        .nav > li.hover > ul {
            left: 0;
            display: block;
        }

        .nav li li.hover > ul {
            left: 100%;
            top: 0;
            display: block;
        }
    }
    /* end .topMenu */
    /*********************
CONTENT STYLES
*********************/
    /* content area all inner pagesset different or alternate row colours here*/

    #intro,
    #breadcrumbs {
        background: darken($white, 0%);
    }
    /* content area all inner pages */
    #content, #bottomBlocks, #footer {
        background: $white;
        position: relative
    }





    img:not(#bottomBlocks img):not(div.columnlayout.grid-2 img):not(body.Home #hero img):not(#hero img) {
        border-radius: 2px 2px 100px 2px;
    }

    .col-2 {
        display: grid;
        grid-gap: 0.75em;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 1em;

        > div, > li {
            grid-column: span 2;
        }
    }


    :target {
        scroll-margin-top: 15vh;
    }

    .legend {
        span {
            margin-right: 0.25em
        }

        i {
            margin-right: 0.5em
        }
    }

    .fa-ul {
        padding-left: 60px;
    }



    p {
    }


    ul, ol, table, dl {
    }

    #leftcol ul:not(.gallerydetails) {
        list-style: none;
        padding: 0 0 0 0;

        li:not(.slick-slide):not(.backgroundText li) {
            position: relative;
            padding-left: 2em;
            margin-bottom: 0.5em;

            &:before {
                content: "\f058";
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                color: $color-secondary;
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                font-size: 1.25em;
                line-height: 1.5;
            }
        }
    }

    body.events .wrap-txt .events {
        position: relative;
        padding-left: 1.5em;
        margin-bottom: 0.5em;
    }

    body.events .wrap-txt .events:before {
        content: "\f073";
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        color: $color-secondary;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        font-size: 1.25em;
        line-height: 1.5;
    }

    ol {

        li {
        }
    }

    hr {
        margin: 2.5em 0 0 0;
        border: none;
        border-bottom: 1px dotted $color-secondary;
    }

    blockquote {
        margin: 0 0 0 0;
        text-align: left;
    }

    blockquote {
        padding: 1em;

        p {
            padding-left: 2em;
            margin-top: 0;
        }

        h5 {
            margin: 0.4em 0;
        }

        &:before {
            content: "\f10d";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            display: block;
            float: left;
            padding-right: 0.5em;
            line-height: 1.2;
        }

        a {
            text-decoration: none;
        }

        em {
            text-align: right;
        }
    }

    dl {
    }

    dt {
    }

    dd {
        margin-left: 0;
        font-size: 0.9em;
        color: #787878;
        margin-bottom: 1.5em;
    }

    img {
        /*margin: 0 0 1.5em 0;*/
        max-width: 100%;
        height: auto;
    }


    #breadcrumbs {
        /*border-bottom: 1px dotted lighten($color-secondary, 0%);*/

        > div {
            /*text-align: center;*/
            div {
                display: inline;
            }
        }
    }



    .img-wrap {
        position: relative;
        z-index: 0;
        @include transition(all 0.4s ease-in-out);

        picture {
            aspect-ratio: 450/450;
            position: relative;
        }

        img, picture {
            z-index: 1;
            width: 100% !important;
            height: 100% !important;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: 50% 50%;
            object-position: 50% 50%;
            opacity: 1;
            display: block;
            border-radius: 2px 2px 100px 2px;
        }


        &:hover {
            /* transform: translateY(-0.25em);
        @include transition(all 0.4s ease-in-out);
        cursor: pointer;*/
        }
    }

    div.first {
        .img-wrap {
        }
    }
    /*********************************
 BLOCKS
*********************************/

    #div389, #div395, #div402, #leftBlocks:not(body.take-action #leftBlocks) {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lighten($color-quaternary, 20%);
    }

    .block {
    }

    .columnlayout {
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        padding-left: 0;
        justify-content: center;
        /*turn off basic li styling*/
        padding: 0 0 0em 0;
        margin-bottom: 1.1em;

        li:before {
            display: none;
        }

        li {
            margin-bottom: 0em;
            padding-left: 0em;
        }

        > div, li, article {
            display: inline-block;
            vertical-align: top;
            flex: 1 1 auto;
            flex-grow: 0;
            width: 100%;
            padding: 0 1em;

            > div {
                width: auto;
            }
        }

        &.services {
            > div, > article {
                padding: 0 0em;
                margin: 0.5em;
                background: #e6f8fc;
                border-radius: 2px;

                > div.text-wrap {
                    padding: 1em;
                }
            }
        }

        article.newsBlock {
            width: 100%;
        }
    }
    /* #gallery */
    #gallery li {
        padding: 0 0.25em;
    }
    /* #gallery */
    /*Home services blocks style 2*/
    .columnlayout.grid-2, .columnlayout.grid-3 {
        justify-content: space-between;

        > div {
            display: flex;
            background: #fff;
            margin: 0.5em;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 2px 2px 100px 2px;
            padding: 1em;
            width: 100%;

            .card-image, .card-item {
            }

            .card-image {
                width: 30%;

                p {
                    margin: 0 0;
                }
            }

            .card-item {
                width: 65%;
                margin-left: 0.5em;

                h3 {
                    margin: 0 0;
                }

                h3 + p {
                    margin-top: 0;
                }
            }
        }
    }
    /* RIGHT BLOCKS */
    #rightcol {
        .block {
            margin-bottom: 1em;

            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }


            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    line-height: 1.6;
                    padding: 0.25em;
                    padding-right: 0;
                    margin-bottom: 0.25em;
                    border-bottom: 1px dotted lighten($black, 30%);

                    a {
                        display: block;
                        text-decoration: none;
                    }

                    a:hover, a.activesibling {
                    }
                }

                li {
                    &:before {
                        content: "\f105";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 1.5;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */
    /*TOP BLOCKS INNER PAGES*/

    body:not(.Home) #topblocks {
        background-color: darken($white, 10%);

        div.block {
            max-height: 350px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            > p {
                margin: 0 0;
            }

            img {
                /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
                width: auto;
                height: auto;
            }

            .caption {
                @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
                width: 95%;
                text-align: center;
                padding: 1em;
            }
        }
    }
    /* CENTERED TEXT WITH OVERLAY HOVER EFFECT */
    /*ALL Pages*/
    /* news */
    .columnlayout.news, .columnlayout.newsBlock {
        margin-bottom: 2em;

        > div, > article {
            padding: 0 0em;
            margin: 1em 1em;
            border-radius: 2px;


            .wrap-txt {
                padding: 0.5em;
            }

            .wrap-img {
                a:not(.button) {
                    display: block;

                    p, picture, img {
                        display: block;
                        margin: 0 0;
                        min-width: 100%;
                    }
                }
            }

            > article {
                padding: 0 0em;
            }
        }
        /*hide admin code from front-end*/
        > div[align="center"], > a {
            display: none;
        }
    }
    /******************************************************************
FORM STYLES
******************************************************************/
    /* show/hid fields on gift voucher form*/
    .hide {
    }


    .respond-form {
        margin: 0em auto;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

    .respond-form, #address {
        /* not nested as not all forms contain a table */
        table {
            width: 100%
        }

        form {
            margin: 0 0;
        }

        li {
            list-style-type: none;
            clear: both;
            margin-bottom: 0.7335em;

            label,
            small {
                display: none;
            }
        }

        fieldset {
            border: 0;
            margin: 0 0;
            padding: 0 0 0 0;
        }

        input[type=text],
        input[type=email],
        input[type=date],
        input[type=url],
        input[type=tel],
        textarea,
        select {
            padding: 1em;
            padding-left: 0;
            background-color: transparent;
            /*background-color:$white;*/
            margin: 0 0 1em 0;
            display: table-cell;
            width: 100%;
            max-width: 100%;
            border: none;
            border-bottom: 2px solid $color-primary;
            color: $color-secondary;
            -webkit-appearance: button;

            &:focus {
                border: none;
                border-bottom: 2px solid $color-primary;
                outline: none;
            }
            /* form validation - comment out as editor doesn't support some of the html5 validation techniques */
            /* &:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}*/
        }

        input[type=checkbox] {
            margin-right: 5px;
        }

        input[name="180_LeaseEnquiry"] {
            margin-bottom: 1em;
        }


        .required {
            background-position: right top;
            background-repeat: no-repeat;
        }

        textarea {
            height: 80px;
        }

        img {
            float: left;

            &.fullwidth {
                max-width: 100%;
            }
        }
    }

    #bottomBlocks .respond-form {
        input[type=text],
        input[type=email],
        input[type=date],
        input[type=url],
        input[type=tel],
        textarea,
        select {
            border-bottom: 2px solid $white;
            background-color: $color-primary;
            color: $white;
        }
    }
    /*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }
    /*********************
FOOTER STYLES
*********************/
    #bottomBlocks, #cta {
        background-color: lighten($color-primary, 0%);
    }

    #bottomBlocks {
        position: relative;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-primary;

        nav {
            ul {
                li {
                    border-bottom: 1px dotted $white;

                    a {
                        display: block;
                        line-height: 2;
                    }
                }
            }
        }

        .row .social-links a {
            display: inline-block;
            vertical-align: baseline;
            margin: 0 0.25em;
        }
    }

    #footer {
        background: $white;

        > div {
            i {
                padding-right: 0.25em;
            }

            nav {
                ul {
                    li {
                        border-bottom: 1px dotted $color-quaternary;

                        a {
                            display: block;
                            line-height: 2;
                        }
                    }
                }
            }
        }

        #copyright {
            .first {
                a {
                }
            }

            .last {
            }
        }
    }
    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: $white;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }

    #leftcol li.slick-slide:before,
    #leftcol ul.responsive .slick-dots li:before {
        display: none;
    }
    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    /*.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-perspective: 1000px;
}*/
    /* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                    padding: 0;

                    .slideshow_caption {
                    }
                }
            }
        }

        button {
            padding: 0.2em;
            height: auto;


            &:before {
                content: "\f104";
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }
    }

    .slick-prev, .slick-next {
        top: 48%;
        z-index: 9;
    }

    .slick-prev {
        left: 1px;
    }

    .slick-next {
        right: 1px;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
    }

    .slick-dots li button:before, .slick-dots li.slick-active button:before {
        color: $black;
    }
    /*Full width slider x3 with captions, dots and arrows */

    ul.responsive {
        /*padding-bottom: 3em;*/
        overflow: visible;

        .slick-list {

            .slick-track {

                .slick-slide {

                    a.imgLink {
                        /*position:relative;display: block; overflow:hidden;*/

                        display: flex;
                        justify-content: center;
                        align-items: top;
                        width: 100%;
                        height: 100%;
                        max-height: 342px;
                        position: relative;

                        img {
                            display: block;
                        }

                        i {
                            position: absolute;
                            opacity: 0.25;
                            @include center();
                        }
                    }

                    .imgLink:after {
                        display: block;
                        content: "";
                        background: $black;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                    }

                    &:hover {
                        cursor: pointer;

                        a i {
                            opacity: 1;
                        }

                        .imgLink:after {
                            opacity: 0.25;
                        }
                    }


                    .slideshow_caption {
                        /*text-align: center;*/
                        /*width: 80%;
                        background-color: rgba(0, 0, 0, 0.7);
                        opacity: 1;
                        padding: 0.5em;
                        bottom:0;
                        @include center(true, false);
                        @include transition(all 0.3s ease-in-out);*/
                    }
                }
            }
        }


        .slick-prev {
            left: 0
        }

        .slick-next {
            right: 0
        }

        .slick-dots {
            bottom: -2em;

            li:before {
                display: none;
            }
        }
    }
    /*Full width slider with arrows */
    ul.responsive2 {
        height: 100%;

        .slick-list {
            height: 100%;

            .slick-track {
                height: 100%;
            }
        }

        .slick-prev, .slick-next {
            bottom: 0;
            top: unset;
            background-color: $color-primary;
        }

        .slick-prev {
            left: 0
        }

        .slick-next {
            left: 32px;
        }
    }
    /*Full width slider with captions */
    ul.responsive3 {
        margin-bottom: 0;


        .slick-list {

            .slick-track {

                .slick-slide {
                    height: 420px;
                    height: 100vh;

                    &:before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.30);
                    }

                    picture, p {
                        display: block;
                        position: relative;
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        /*height: 420px;*/
                        max-width: none;
                        width: auto;
                        position: relative;
                        top: 0%;
                        left: 50%;
                        transform: translate(-50%,0%);
                        -webkit-transform: translate(-50%, 0%);
                        -ms-transform: translate(-50%, 0%);
                        transition: transform 6s;
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;
                        width: 100% !important;
                        object-fit: cover;
                    }

                    p:last-of-type {
                        margin-bottom: 0.75em;
                    }

                    a.button:first-of-type {
                        animation-delay: 1s;
                    }

                    a.button {
                        animation-delay: 1.5s;
                    }
                }

                .slick-slide.slick-active {


                    img {
                        position: relative;
                        top: 0%;
                        left: 50%;
                        transform: translate(-50%, 0%) scale(1.05);
                        -webkit-transform: translate(-50%, 0%) scale(1.05);
                        -ms-transform: translate(-50%, 0%) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }


                .slideshow_caption {
                    opacity: 0;
                    visibility: hidden;
                    display: none;
                    @include transition(all 0.5s ease-in-out);
                    position: relative;
                }

                .slideshow_caption.activate {
                    background: rgba(255, 255, 255,0);
                    opacity: 1;
                    visibility: visible;
                    display: block;
                    text-align: center;
                    @include transition(all 0.5s ease-in-out);
                }

                .slideshow_caption p.h5 {
                    top: -15%;
                    @include center(true, false);
                }
            }
        }
    }
    /************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    /*********************
CUSTOMISE colorbox
*********************/
    #cboxContent {
        padding: 0 40px
    }

    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxNext, #cboxPrevious {
        top: 50%;
    }

    #cboxPrevious {
        left: 0;
    }

    #cboxNext {
        right: 0;
    }

    #cboxTitle {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 1em;
        right: 2.5%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background: darken($color-tertiary, 5%);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);
        border-radius: 50%;


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }


    .contacts-window-container {
        display: none;
    }

    .contacts-window-container.show {
        display: block;
    }

    .contacts-window {
        display: inline-block;
        background-color: #fff;
        width: auto;
        height: auto;
        position: fixed;
        bottom: 65px;
        right: 2.5%;
        left: 6.59%;
        z-index: 50;
        box-shadow: 0px -5px 16px rgba(0,0,0,.2);
        -webkit-animation-delay: 3s; /* Safari 4.0 - 8.0 */
        animation-delay: 3s;
        padding: 1em;
    }

    .icon-social {

        a {
            padding: 0.4em 0em;
            display: inline-block;
            margin: 0em 0.35em;
        }
    }

    .close-contacts-window {
        text-align: right;
    }