/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1, h2, .h2{margin:0.5em 0;} 
h2, .h2 {margin:0.25em 0 0.5em 0; font-size: 2.1em;} 
h1, .h1 { font-size: 2.5em;}
.responsive .h3 {margin-bottom:0; }
ul.responsive3 .slideshow_caption p.h1, .heading h1 { font-size: 3em; }



/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
	padding: 2em 0;
}
body.twoColumns #content #leftcol > div > div{
	padding-bottom: 3em;

}



#homeBlocks .row {
	padding: 3em 0 2em 0;
}


	/*********************
HEADER STYLES
*********************/
	/**************************
HERO - Home
***************************/

	body.Home #hero {
		.caption {
			width: 45%;
			left: 35%;
			h1 {
				font-size: 3.5em;
			}
		}
	}

	body:not(.Home) #hero {
		/*height:300px;*/
		overflow: hidden;

		.block > p:first-of-type img {
			min-width: 100%;
			max-width: initial;
			height: auto;
		}
		/*for headers with overlay craft image instead of H1*/
		p.image-heading {
			bottom: 5%;

			img {
				max-width: 400px;
			}
		}
	}

.custom-shape svg {
	height: 36em;
	width: 195%;
}

.header {
	#mainMenu {
		display: inline-block;
		position: absolute;
		right: 12%;
		bottom: 20%;
	}

	#accountMenu {
		right: 0%;
	}
	
}
	/*********************
NAVIGATION STYLES
*********************/
.header .menu-btn {
	display: none;
	right: 0%;
	left: unset;
	bottom: 0%;
	top: unset;
}

body.Home .clearHeader .topMenu .nav li a.activerootmenulink {
	color: $white;
}

.topMenu {
	text-align: right;
	margin-left: auto;
	margin-right: auto;
	float: right;
	clear: none;


	a.toggleMenu {
		display: none;
	}

	.nav {
		position: relative;
		width: auto;
		border: 0;
		display: inline;


		> li {
			//display: inline-block;
			padding: 0 0.4em;
			display: none;

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: inline-block;
			}

			> a {
				outline: none;

				&:hover, &:focus {
					background: transparent;
				}

				&.parent:after {
					/*content: "\f107";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;*/
				}
			}
			/*Prominent contact link*/
			&:first-child {
				display: none;
			}
		}

		li {
			position: relative;

			a {
				padding: 0.5em 0em;
				background: none;
				border-bottom: none;


				&.parent:before {
					display: none;
				}
			}
			/* highlight current page */
			a.activerootmenulink {
				color: $color-primary;
			}

			&:first-child {
			}

			&:last-child {
			}
			/*
			plan your menus and drop-downs wisely.
			*/
			ul,
			ul.sub-menu,
			ul.children {
				position: absolute;
				z-index: 9999;
				left: -9999px;
				border-top: 0px solid $color-primary;

				li {

					a {
						padding: 0.5em 0.5em;
						display: block;
						width: 250px;
						border-top: none;
						border-radius: 0;
						margin-right: 0;
						//background: darken($black, 5%);
						background: $white;
						border-bottom: 1px solid rgba(130, 154, 193, 0.21);
						color: $black;

						&:hover,
						&:focus {
							border-top: none;
							background: darken($white, 8%);
							color: $color-primary;
						}

						&:link {
						}
					}

					&:first-child {
					}

					&:last-child {

						a {
						}
					}
					/*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
					ul {
						border-top: none;
					}
				}
			}
			/* showing sub-menus */
			&:hover ul {
			}
		}
		/* end .menu ul li */
	}
	/* end .nav */
}
/* end .topMenu */


	.topMenu {
		.nav {
			> li {
				padding: 0 0.7em;
			}
		}
	}
	/*********************
CONTENT STYLES
*********************/

	#content #leftcol > div > .first {
		padding: 0 2em;
	}
	/*********************
BLOCK LAYOUT
**********************/

body.Home #homeBlocks {
	background: transparent url('../images/slogan.svg') no-repeat bottom 20px right 20px;
	background-size: 320px 320px;
}


	.columnlayout {

		> div, li {
		}
		/*Top row needs border adding seperatley to prevent double borders*/
		> div:nth-child(4), li:nth-child(4) {
		}
	}


	#bottomBlocks {
		.block {
			margin-bottom: 0em;
		}

		h2 {
			margin-bottom: 1em;
		}
	}
	/* LEFT BLOCKS - Mission statement*/
	#homeMission {
		.row {
			height: 60vh;
			display: flex;
			align-items: center;
		}
	}
	/*Full width slider */
	ul.responsive3 {

		.slick-list {
			.slick-track {
				.slick-slide {

					img {
					}
				}
			}
		}
	}

#homeBlocks .columnlayout {

	> div {
		width: 23%;
		margin-bottom: 2em;
	}
}

#bottomBlocks {
	background: $color-primary url('../images/sloganwhite.svg') no-repeat bottom 30px right 90px;
	background-size: 330px 330px;
}


@media (min-width: 1400px) {

	body.Home #homeBlocks {
		background: transparent url('../images/slogan.svg') no-repeat bottom 20px right 100px;
		background-size: 320px 320px;
	}
}

@media (min-width: 1600px) {

	body.Home #homeBlocks {
		background: transparent url('../images/slogan.svg') no-repeat bottom 20px right 280px;
		background-size: 320px 320px;
	}
}